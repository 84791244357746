
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>配件列表</el-breadcrumb-item>
      <el-breadcrumb-item>图纸列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    内容开始-->
    <el-card class="box-card">
      <el-button type="primary" @click='opentan()'>添加信息</el-button>
      <!--    列表开始-->
      <el-table
        :data="tableData"
        stripe
        style="width: 100%">
        <el-table-column
          prop="e_name"
          label="图纸名称"
        >
        </el-table-column>
        <el-table-column
          prop="drawing_name"
          label="所属型号"
        >

        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click='editfun(scope.row)'></el-button>
            <el-button size="mini" type="success" icon="el-icon-view" @click='lookfun(scope.row.id)'></el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click='delfun(scope.row.id)'></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页-->
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!--  弹窗内容-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-form ref="dform" :model="dform" label-width="120px" :rules="drules">
        <el-form-item label="图纸名称" prop="drawing_name">
          <el-input v-model="dform.drawing_name"></el-input>
        </el-form-item>
        <el-form-item label="上传图纸" prop='type_id'>
          <el-upload
            action="www.baidu.com"
            show-file-list
            :http-request="httpRequest"
            list-type="text"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            :on-success='okla'
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>

        </el-form-item>
        <el-form-item label="选择型号" prop='type_id'>
          <el-select
            v-model="dform.equipment_id"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading">
            <el-option
              v-for="item in optionsdata"
              :key="item.id"
              :label="item.e_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="cancelfun()">取 消</el-button>
    <el-button :loading="loading" v-if="tantype!=='look'" type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
// 图片上传
import { Upimg, Upppp } from '../../../api/upimg'
import {
  AddDrawing,
  DelDrawing,
  EditDrawing,
  GetDrawingList, GetEquipmentList,
  GetOneDrawing, UpParts
} from '../../../api/vehicle/autoconfigure'

export default {
  components: {
    Fenye
  },
  data() {
    return {
      loading:false,
      imgd: '',
      fileList: [],
      dialogVisible: false, // 弹窗控制
      filearr: [],
      dform: {
        drawing_name: '',
        drawing_file: [],
        equipment_id: 1,

      },
      // 选项
      optionsdata: [],
      // 弹窗
      // 按钮的状态
      tantype: 'add',
      // 验证规则
      drules: {
        drawing_name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]

      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      // 列表数据
      tableData: []
    }
  },
  mounted() {
    this.getdrawinglistfun()
  },
  methods: {
    bianzhi(newSize) { // 分页值变化
      this.pageData.size = newSize
      this.getdrawinglistfun()
    },
    bianye(newPape) { // 分页页数变化
      this.pageData.page = newPape
      this.getdrawinglistfun()
    },
    editfun(ev) { // 打开编辑
      // 赋值
      this.dform = JSON.parse(JSON.stringify(ev))
      // 修改属性为修改
      this.tantype = 'edit'
      // 打开弹窗
      this.opentan()
    },
    lookfun(ld) { // 打开查看

      this.onedrawingfun(ld)
      // 修改属性为修改
      this.tantype = 'look'
      // 打开弹窗
      this.opentan()
    },

    delfun(id) { // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deldrawingfun(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 打开弹窗
    opentan() {
      this.dialogVisible = true

    },
    handlePictureCardPreview(file) { // 上传时钩子

    },
    okla(response, file, fileList) { // 成功时
    },
    handleRemove(file, fileList) { // 移除的钩子
      const arrindex = this.filearr.findIndex(item => item.name === file.name)
      this.filearr.splice(arrindex, 1)

    },
    // 搜索选择
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        this.getequipmentistfun(query)
      } else {
        this.options = [];
      }
    },
    // 确定添加
    addgoto(type) {
      this.dform.drawing_file = this.filearr
      switch (type) {
        case 'add':
          console.log(type)
          this.$refs['dform'].validate(v => {
            if (v) {
              this.adddrawingfun(this.dform)
            }
          })
          break
        case 'edit':
          this.$refs['dform'].validate(v => {
            if (v) {
              this.editdrawingfun(this.dform)
            }
          })
          console.log(type)
          break
      }
      console.log(this.dform)
    },
    // 取消
    cancelfun() {
      this.handleClose()
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {}
      this.tantype = 'add'
      this.filearr = []
    },
    // 请求区ლ(´ڡ`ლ)
    // 获取设备型号
    async getequipmentistfun(key) {
      const { data } = await GetEquipmentList({ key })
      console.log(data.data.equipment_Info)
      this.optionsdata = data.data.equipment_Info
      this.loading = false
    },
    // 获取图纸
    async getdrawinglistfun() {
      const { data } = await GetDrawingList(this.pageData)
      this.getanpostfun(data, '获取')
      this.tableData = data.data.list
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },
    // 添加图纸
    async adddrawingfun(v) {
      const { data } = await AddDrawing(v)
      this.getanpostfun(data, '添加', this.getdrawinglistfun())
    },
    // 删除图纸
    async deldrawingfun(id) {
      const { data } = await DelDrawing({ id })
      this.getanpostfun(data, '删除', this.getdrawinglistfun())
    },
    // 修改
    async editdrawingfun(ev) {
      const { data } = await EditDrawing(ev)
      this.getanpostfun(data, '修改', this.getdrawinglistfun())
    },
    // 查看
    async onedrawingfun(id) {
      const { data } = await GetOneDrawing({ id })
      this.dform = data.data.drawing
      console.log(this.dform)
    },
    // 图片上传
    async httpRequest(options) {
      // console.log(options)
      this.loading = true
      const fd = new FormData()
      fd.append('file[]', options.file)
      const res = await Upimg(fd)
      // 图片上传成功后把图片push到scroll中用于修改
      console.log(res)

      if (res.status !== 200) {
        return this.$message.error('上传失败')
        this.loading = false
      } else {
        // this.dform.fileList.push(res.data[0].url)
        const fiidobj = { name: options.file.name, url: res.data[0].url }
        this.filearr.push(fiidobj)
        // console.log(this.filearr)
        this.loading = false
      }
    },
    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        this.handleClose()
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
<style scoped>

</style>
